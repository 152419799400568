class CampaignEditUtilities {
  /**
   * Update catalog drop downs items.
   */
  static updateCatalogDropDowns() {
    let html = '';
    html += '<option value="">Select One</option>';
    const catalogKeys = Object.keys(window.catalogsForAdAccount);

    if (catalogKeys.length > 0) {
      // Update all catalog dropdowns
      catalogKeys.forEach((key) => {
        const { id, name } = window.catalogsForAdAccount[key];
        html += `<option value="${id}">${name}</option>`;
      });
    }

    $('select.catalogDropdown').each(function processCatalogDropdowns() {
      const currentSelection = $(this).val();

      $(this).html(html).selectpicker('refresh').val(currentSelection);
      $('#essentials-form').trigger('reinitialize.areYouSure');
    });
  }

  /**
   * Lock catalog drop downs to a specific catalog ID.
   *
   * @param catalogID
   */
  static lockCatalogDropDowns(catalogID) {
    $('select.catalogDropdown').val(catalogID).prop('disabled', true).selectpicker('refresh')
      .trigger('change');

    // If there are no DPA adsets then lets allow the user to go ahead and switch the campaign catalog
    if ($('.list-group-item.adsetlink[data-isdpa="1"]').length === 0) {
      $('#essentials-form select#fb_catalog_id').prop('disabled', false).selectpicker('refresh');
    }
  }
}

export default CampaignEditUtilities;
