import moment from 'moment';
import swal from 'sweetalert2';
import Utilities from '../../Utilities';
import { parseFloatWithNaN } from '../../utilities/type_conversions';

const campaignID = Utilities.getUrlParameter('campaign_id');
const projectID = Utilities.getUrlParameter('project');

let $addBudgetModal;
let $addBudgetModalForm;
let $addBudgetModalDailyBudget;
let $addBudgetModalAddToLifeTimeBudget;
let $addBudgetModalLifetimeBudgetEnd;
let $addBudgetModalSubmitButton;
let $addBudgetModalUseCalculatedBudgetDaily;
let $allowOverrun;
let $allowOverrunItems;
let $budgetAtMinimumEnd;
let $calculatedDailyBudget;
let $dailyBudget;
let $emergencyExtensionModal;
let $emergencyExtensionModalForm;
let $emergencyExtensionModalSubmitButton;
let $lifetimeBudgetEnd;
let $lifetimeBudgetStart;
let $overrideEnabled;
let $overrideDailyBudgetEnd;
let $overrideDailyBudgetStart;
let $overrunDailyBudget;
let $overrunDailyBudgetEnd;
let $scalingBudgetAllowed;
let $scalingBudgetAllowedItems;
let $updateBudgetModal;
let $updateBudgetModalForm;
let $updateBudgetModalDailyBudget;
let $updateBudgetModalHasUninvoicedBudget;
let $updateBudgetModalLifetimeBudgetStart;
let $updateBudgetModalLifetimeBudgetEnd;
let $updateBudgetModalSubmitButton;
let $updateBudgetModalUninvoicedBudget;
let $updateBudgetModalUninvoicedBudgetFormGroup;
let $updateBudgetModalUninvoicedBudgetBasedOnScaling;
let $updateBudgetModalUninvoicedBudgetEnd;
let $updateBudgetModalUninvoicedBudgetItems;
let $updateBudgetEnded;

/**
 *
 * FUNCTIONS
 *
 */

/**
 * Calculate and show new Daily Budget.
 */
function calculateAndShowNewDailyBudget() {
  const currentEndDate = moment($lifetimeBudgetEnd.val());
  const newEndDate = moment($addBudgetModalLifetimeBudgetEnd.val());
  const budgetToAdd = parseFloatWithNaN($addBudgetModalAddToLifeTimeBudget.val());

  let numDaysAdded = newEndDate.diff(currentEndDate, 'days');
  numDaysAdded = (numDaysAdded > 1) ? numDaysAdded : 1;

  const calculatedBudget = (budgetToAdd > 0) ? (budgetToAdd / numDaysAdded).toFixed(2) : 0;
  const $calculatedDailyBudgetContainer = $calculatedDailyBudget.closest('.form-group');

  if (numDaysAdded > 0 && budgetToAdd > 0) {
    $calculatedDailyBudgetContainer.removeClass('d-none');
  } else {
    $calculatedDailyBudgetContainer.addClass('d-none');
  }

  $calculatedDailyBudget.html(`$${calculatedBudget}`);
  $addBudgetModalDailyBudget.val(calculatedBudget);
}

/**
 * Initialize the lifetime budget start date picker.
 *
 * @param $startDatePicker
 * @param $endDatePicker
 * @param date
 * @param maxDate
 */
function startDateLifetimeBudget($startDatePicker, $endDatePicker, date = false, maxDate = false) {
  $startDatePicker.daterangepicker({
      singleDatePicker: true,
      autoUpdateInput: false,
      startDate: date.length ? date : false,
      maxDate: maxDate.length ? maxDate : false,
    },
    (start) => {
      const dateSelected = start.format('MM/DD/YYYY');
      $startDatePicker.val(dateSelected);
      // eslint-disable-next-line no-use-before-define
      endDateLifetimeBudget($startDatePicker, $endDatePicker, $endDatePicker.val(), dateSelected);
    });
  if (!date) { $startDatePicker.val(''); }
}

/**
 * Initialize the lifetime budget end date picker.
 *
 * @param $startDatePicker
 * @param $endDatePicker
 * @param date
 * @param minDate
 */
function endDateLifetimeBudget($startDatePicker, $endDatePicker, date = false, minDate = false) {
  $endDatePicker.daterangepicker({
      singleDatePicker: true,
      autoUpdateInput: false,
      startDate: date.length ? date : false,
      minDate: minDate.length ? minDate : false,
    },
    (start) => {
      const dateSelected = start.format('MM/DD/YYYY');
      $endDatePicker.val(dateSelected);
      startDateLifetimeBudget($startDatePicker, $endDatePicker, $startDatePicker.val(), dateSelected);
    });
  if (!date) { $endDatePicker.val(''); }
}

/**
 * Initialize the override start date picker.
 *
 * @param date
 * @param maxDate
 */
function startDateOverride(date = false, maxDate = false) {
  $overrideDailyBudgetStart.daterangepicker({
      singleDatePicker: true,
      autoUpdateInput: false,
      startDate: date.length ? date : moment().format('MM/DD/YYYY'),
      maxDate: maxDate.length ? maxDate : false,
    },
    (start) => {
      const dateSelected = start.format('MM/DD/YYYY');
      $overrideDailyBudgetStart.val(dateSelected);
      // eslint-disable-next-line no-use-before-define
      endDateOverride($overrideDailyBudgetEnd.val(), dateSelected);
    });
  if (!date) { $overrideDailyBudgetStart.val(''); }
}

/**
 * Initialize the override end date picker.
 *
 * @param date
 * @param minDate
 */
function endDateOverride(date = false, minDate = false) {
  const today = moment().format('MM/DD/YYYY');
  $overrideDailyBudgetEnd.daterangepicker({
      singleDatePicker: true,
      autoUpdateInput: false,
      startDate: date.length ? date : today,
      minDate: minDate.length && minDate > today ? minDate : today,
    },
    (start) => {
      const dateSelected = start.format('MM/DD/YYYY');
      $overrideDailyBudgetEnd.val(dateSelected);
      startDateOverride($overrideDailyBudgetStart.val(), dateSelected);
    });
  if (!date) { $overrideDailyBudgetEnd.val(''); }
}

/**
 * Initialize the override end date picker.
 */
function initOverrideBudgetDatePickers() {
  const overrideStart = $overrideDailyBudgetStart.val();
  const overrideEnd = $overrideDailyBudgetEnd.val();

  // Init the datepickers
  startDateOverride(overrideStart, overrideEnd);
  endDateOverride(overrideEnd, overrideStart);
}

/**
 * Initialize the overrun end date picker.
 */
function endDateOverrunBudget() {
  $overrunDailyBudgetEnd.daterangepicker({
      singleDatePicker: true,
      autoUpdateInput: false,
    },
    (start) => {
      $overrunDailyBudgetEnd.val(start.format('MM/DD/YYYY'));
    });
}

/**
 * Initialize the overrun end date picker.
 */
function endDateBudgetAtMinimum() {
  $budgetAtMinimumEnd.daterangepicker({
      singleDatePicker: true,
      autoUpdateInput: false,
    },
    (start) => {
      $budgetAtMinimumEnd.val(start.format('MM/DD/YYYY'));
    });
}

/**
 * Trigger error swal alert.
 *
 * @param errorMessage
 * @returns {Promise<SweetAlertResult<Awaited<unknown>>>}
 */
function triggerErrorSwal(errorMessage) {
  return swal.fire({
    icon: 'error',
    title: errorMessage,
    timer: 3000,
    customClass: {
      confirmButton: 'btn btn-primary',
    },
  });
}

/**
 * Trigger budget doubling swal alert.
 *
 * @param currentDailyBudget
 * @param newDailyBudget
 * @returns {Promise<SweetAlertResult<Awaited<unknown>>>}
 */
function triggerBudgetDoublingSwal(currentDailyBudget, newDailyBudget) {
  return swal.fire({
    allowEscapeKey: false,
    allowOutsideClick: false,
    confirmButtonText: 'Sign-off on Double Budget',
    text: `It appears you are increasing the budget from ${currentDailyBudget} to ${newDailyBudget} which is double, or more, than the current budget. Are you sure you want to do this?`,
    reverseButtons: true,
    showCancelButton: true,
    title: 'Budget Increase Warning',
    type: 'warning',
    customClass: {
      confirmButton: 'btn btn-primary',
      cancelButton: 'btn btn-secondary',
    },
  });
}

/**
 * Process add budget request.
 *
 * @param form
 */
function processAddBudgetRequest(form) {
  $.ajax({
    url: `/campaign/edit/budget/process?project_id=${projectID}&campaign=${campaignID}&action=add-budget`,
    type: 'post',
    data: $(form).serialize(),
    dataType: 'json',
  })
    .done((data) => {
      $('.loader, #loading-overlay').hide();

      if (data.success) {
        swal.fire({
          icon: 'success',
          title: 'Campaign Budget Updated Successfully',
          timer: 3000,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(() => {
          $('.loader, #loading-overlay').show();
          $addBudgetModalSubmitButton.prop('disableddisabled', false);
          $addBudgetModal.modal('hide');
          window.location.reload();
        });
      } else {
        triggerErrorSwal(data.message).then(() => {
          $addBudgetModalSubmitButton.prop('disableddisabled', false);
        });
      }
    })
    .fail((jqXHR, status, error) => {
      triggerErrorSwal(error).then(() => {
        $addBudgetModalSubmitButton.prop('disabled', false);
      });
    });
}

/**
 * Process a Budgeting settings form.
 *
 * @param form
 * @param formName
 * @param $submitButton
 * @param {string} action
 * @param {boolean} reloadWindow
 * @param {string} additionalNoticesTitle
 * @param getAdditionalNoticesFunction
 * @param {string} additionalURLParams
 */
function processBudgetingForm(form, formName, $submitButton, action, reloadWindow = false, additionalNoticesTitle = '', getAdditionalNoticesFunction = null, additionalURLParams = '') {
  $('.loader, #loading-overlay').show();
  $submitButton.prop('disabled', true);

  $.ajax({
    url: `/campaign/edit/budget/process?project_id=${projectID}&campaign=${campaignID}&action=${action}${additionalURLParams}`,
    type: 'post',
    data: $(form).serialize(),
    dataType: 'json',
  })
    .done((data) => {
      $('.loader, #loading-overlay').hide();

      if (data.success) {
        swal.fire({
          icon: 'success',
          title: `${formName} Updated Successfully`,
          timer: 3000,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(() => {
          $submitButton.prop('disabled', false);

          const additionalNotices = (getAdditionalNoticesFunction == null) ? '' : getAdditionalNoticesFunction();

          if (additionalNotices.length > 0) {
            swal.fire({
              icon: 'warning',
              title: additionalNoticesTitle,
              html: additionalNotices,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            }).then(() => {
              if (reloadWindow) {
                $('.loader, #loading-overlay').show();
                window.location.reload();
              }
            });
          } else if (reloadWindow) {
            $('.loader, #loading-overlay').show();
            window.location.reload();
          }
        });
      } else {
        triggerErrorSwal(data.message).then(() => {
          $submitButton.prop('disabled', false);
        });
      }
    })
    .fail((jqXHR, status, error) => {
      triggerErrorSwal(error).then(() => {
        $submitButton.prop('disabled', false);
      });
    });
}

/**
 * Process update budget request.
 *
 * @param form
 */
function processUpdateBudgetRequest(form) {
  $.ajax({
    url: `/campaign/edit/budget/process?project_id=${projectID}&campaign=${campaignID}&action=update-budget`,
    type: 'post',
    data: $(form).serialize(),
    dataType: 'json',
  })
    .done((data) => {
      $('.loader, #loading-overlay').hide();

      if (data.success) {
        swal.fire({
          icon: 'success',
          title: 'Campaign Budget Updated Successfully',
          timer: 3000,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(() => {
          $('.loader, #loading-overlay').show();
          $updateBudgetModalSubmitButton.prop('disabled', false);
          $updateBudgetModal.modal('hide');
          window.location.reload();
        });
      } else {
        triggerErrorSwal(data.message).then(() => {
          $updateBudgetModalSubmitButton.prop('disabled', false);
        });
      }
    })
    .fail((jqXHR, status, error) => {
      triggerErrorSwal(error).then(() => {
        $updateBudgetModalSubmitButton.prop('disabled', false);
      });
    });
}

/**
 * Set up a Budgeting settings form with validation handling.
 *
 * @param $form
 * @param formName
 * @param $submitButton
 * @param {string} action
 * @param {boolean} reloadWindow
 * @param {string} additionalNoticesTitle
 * @param getAdditionalNoticesFunction
 * @param {string} additionalURLParams
 */
function setupBudgetingForm($form, formName, $submitButton, action, reloadWindow = false, additionalNoticesTitle = '', getAdditionalNoticesFunction = null, additionalURLParams = '') {
  $form.validate({
    invalidHandler() {
      $(this).addClass('was-validated');
    },
    submitHandler(form) {
      processBudgetingForm(form, formName, $submitButton, action, reloadWindow, additionalNoticesTitle, getAdditionalNoticesFunction, additionalURLParams);
    },
  });
}

/**
 * Handle toggling on Budgeting Section items.
 * @param $toggle
 * @param $items
 */
function setupBudgetingSectionHandler($toggle, $items) {
  $toggle.on('change', () => {
    $items.prop('readonly', !$toggle.is(':checked') || $toggle.is('[disabled]'));
  }).trigger('change');
}

/**
 *
 * INITS
 *
 */

function initAddBudget() {
  /**
   *
   * INITIAL LOAD EVENTS
   *
   */
  $addBudgetModalForm.validate({
    invalidHandler() {
      $(this).addClass('was-validated');
    },
    submitHandler(form) {
      $('.loader, #loading-overlay').show();
      $addBudgetModalSubmitButton.prop('disabled', true);

      // If Daily Budget has doubled then ask the user to sign off on it
      let budgetHasDoubled = false;
      let currentDailyBudget = 0;
      let newDailyBudget = 0;

      if ($addBudgetModalUseCalculatedBudgetDaily.is(':checked')) {
        currentDailyBudget = $dailyBudget.val();
        newDailyBudget = $addBudgetModalDailyBudget.val();
        budgetHasDoubled = (parseFloatWithNaN(newDailyBudget) >= parseFloatWithNaN(currentDailyBudget) * 2);
      }

      if (budgetHasDoubled) {
        triggerBudgetDoublingSwal(currentDailyBudget, newDailyBudget).then((result) => {
          if (result.value) {
            processAddBudgetRequest(form);
          } else {
            $('.loader, #loading-overlay').hide();

            triggerErrorSwal('Your budget has NOT been added. Please change the Daily Budget and try again.').then(() => {
              $addBudgetModalSubmitButton.prop('disabled', false);
            });
          }
        });
      } else {
        processAddBudgetRequest(form);
      }
    },
  });
  /** INITIAL LOAD EVENTS -- END */

  /**
   *
   * EVENT TRIGGER HANDLERS
   *
   */
  $('button#addBudget').on('click', (event) => {
    event.preventDefault();
    $addBudgetModalLifetimeBudgetEnd.daterangepicker({
        singleDatePicker: true,
        autoUpdateInput: false,
        minDate: $lifetimeBudgetStart.val(),
      },
      (start) => {
        const dateSelected = start.format('MM/DD/YYYY');
        $addBudgetModalLifetimeBudgetEnd.val(dateSelected);
        calculateAndShowNewDailyBudget();
      });

    $addBudgetModal.appendTo('body').modal('show');
  });

  $addBudgetModalSubmitButton.on('click', (event) => {
    event.preventDefault();
    $addBudgetModalForm.submit();
  });

  $addBudgetModalAddToLifeTimeBudget.on('change', () => {
    calculateAndShowNewDailyBudget();
  });
  /** EVENT TRIGGER HANDLERS -- END */
}

function initUpdateBudget() {
  /**
   *
   * INITIAL LOAD EVENTS
   *
   */
  $updateBudgetModalForm.validate({
    invalidHandler() {
      $(this).addClass('was-validated');
    },
    submitHandler(form) {
      $('.loader, #loading-overlay').show();
      $updateBudgetModalSubmitButton.prop('disabled', true);

      // If Daily Budget has doubled then ask the user to sign off on it
      const currentDailyBudget = $dailyBudget.val();
      const newDailyBudget = $updateBudgetModalDailyBudget.val();
      const budgetHasDoubled = (parseFloatWithNaN(newDailyBudget) >= parseFloatWithNaN(currentDailyBudget) * 2);
      if (budgetHasDoubled) {
        triggerBudgetDoublingSwal(currentDailyBudget, newDailyBudget).then((result) => {
          if (result.value) {
            processUpdateBudgetRequest(form);
          } else {
            $('.loader, #loading-overlay').hide();

            triggerErrorSwal('Your budget has NOT been updated. Please change the Daily Budget and try again.').then(() => {
              $updateBudgetModalSubmitButton.prop('disabled', false);
            });
          }
        });
      } else {
        processUpdateBudgetRequest(form);
      }
    },
  });
  /** INITIAL LOAD EVENTS -- END */

  /**
   *
   * EVENT TRIGGER HANDLERS
   *
   */
  $('button#updateBudget').on('click', (event) => {
    event.preventDefault();
    startDateLifetimeBudget($updateBudgetModalLifetimeBudgetStart, $updateBudgetModalLifetimeBudgetEnd, $updateBudgetModalLifetimeBudgetStart.val(), $updateBudgetModalLifetimeBudgetEnd.val());
    endDateLifetimeBudget($updateBudgetModalLifetimeBudgetStart, $updateBudgetModalLifetimeBudgetEnd, $updateBudgetModalLifetimeBudgetEnd.val(), $updateBudgetModalLifetimeBudgetStart.val());
    $updateBudgetModalUninvoicedBudgetEnd.daterangepicker({
        singleDatePicker: true,
        autoUpdateInput: false,
        minDate: $lifetimeBudgetStart.val(),
      },
      (start) => {
        const dateSelected = start.format('MM/DD/YYYY');
        $updateBudgetModalUninvoicedBudgetEnd.val(dateSelected);
        calculateAndShowNewDailyBudget();
      });
    $updateBudgetModal.appendTo('body').modal('show');
  });

  $updateBudgetModalUninvoicedBudget.on('change', () => {
    calculateAndShowNewDailyBudget();
  });

  $updateBudgetModalSubmitButton.on('click', (event) => {
    event.preventDefault();
    $updateBudgetModalForm.submit();
  });

  $updateBudgetModalHasUninvoicedBudget.on('change', function updateBudgetModalHasUninvoicedBudgetChange() {
    if ($(this).is(':checked')) {
      $updateBudgetModalUninvoicedBudgetItems.show();
      $updateBudgetModalUninvoicedBudgetBasedOnScaling.trigger('change');
    } else {
      $updateBudgetModalUninvoicedBudgetItems.hide();
    }
  }).trigger('change');

  $updateBudgetModalUninvoicedBudgetBasedOnScaling.on('change', function updateBudgetModalUninvoicedBudgetBasedOnScalingChange() {
    if ($updateBudgetModalHasUninvoicedBudget.is(':checked')) {
      if ($(this).is(':checked')) {
        $updateBudgetModalUninvoicedBudgetFormGroup.hide();
      } else {
        $updateBudgetModalUninvoicedBudgetFormGroup.show();
      }
    }
  }).trigger('change');
  /** EVENT TRIGGER HANDLERS -- END */
}

function initViewHistory() {
  /**
   *
   * INITIAL LOAD EVENTS
   *
   */
  /** INITIAL LOAD EVENTS -- END */

  /**
   *
   * EVENT TRIGGER HANDLERS
   *
   */
  $('button.viewBudgetHistory').on('click', (event) => {
    event.preventDefault();
    $('.loader, #loading-overlay').show();

    $.getJSON(`/campaign/edit/budget/history/get?project_id=${projectID}&campaign=${campaignID}`, (data) => {
      if (data.success) {
        let historyHTML = '';
        const skippableKeys = ['budget_history_id', 'campaign_auto_optimize_setting_history_id', 'campaign_auto_optimize_amazon_products_for_scaling_history_id', 'campaign_auto_optimize_shopify_products_for_scaling_history_id', 'campaign_build_history_id', 'campaign_id', 'build_id', 'created', 'modified', 'created_by', 'created_by_first_name', 'created_by_last_name', 'name', 'requested_by_first_name', 'requested_by_last_name'];
        const yesOrNoEnumFields = ['allow_overrun', 'autotest_enabled', 'budget_at_minimum', 'budget_scaling', 'budget_scaling_allowed', 'double_budget_approval', 'override_enabled', 'override_max_warm_multiplier', 'pending_allow_overrun', 'pending_autotest_enabled', 'pending_auto_optimize', 'pending_budget_scaling', 'pending_override_enabled', 'shopify_scaling_exclude_recurring_and_prepaid', 'utilize_for_overrun'];

        $.each(data.history.sort((a, b) => (new Date(b.created) - new Date(a.created))), (i, item) => {
          if (item !== null) {
            let changes = '';
            let nonSkippableFields = 0;
            // eslint-disable-next-line eqeqeq
            const liClass = (item.created_by == -1) ? 'Monkedia' : 'danger';

            if (item.name != null) {
              changes += `<strong>Name:</strong> ${item.name}<br />`;
            }

            $.each(item, (k, v) => {
              if (skippableKeys.indexOf(k) === -1 && (v !== null || (item.name != null && k === 'end_date'))) {
                const name = Utilities.ucWords(k.toString().replace(/_/g, ' '));
                let value = v;

                if (yesOrNoEnumFields.indexOf(k) !== -1) {
                  value = (value === '1') ? 'Yes' : 'No';
                  value = (v == null) ? 'Yes' : value;
                }

                if (k.toString() === 'amazon_products_for_scaling' || k.toString() === 'shopify_products_for_scaling') {
                  value = `<br />${value.replace(/, /g, '<br />')}`;
                }

                changes += `<strong>${name}:</strong> ${value}<br />`;
                nonSkippableFields += 1;
              }
            });

            if (item.requested_by_first_name != null) {
              changes += `<strong>Requested By:</strong> ${item.requested_by_first_name} ${item.requested_by_last_name}`;
            }

            if (nonSkippableFields > 0) {
              historyHTML += `
                    <li class="${liClass}">
                        <section class="d-flex justify-content-between ">
                            <span >${item.created_by_last_name}, ${item.created_by_first_name}</span>
                            <span >${item.created}</span>
                        </section>
                        <p class="p-t-0_5">${changes}</p>
                    </li>`;
            }
          }
        });

        $('#viewBudgetHistoryModal .modal-body .timeline').html(historyHTML);
        $('.loader, #loading-overlay').hide();
        $('#viewBudgetHistoryModal').appendTo('body').modal('show');
      } else {
        $('.loader, #loading-overlay').hide();
        triggerErrorSwal(data.message);
      }
    }).error(() => {
      $('.loader, #loading-overlay').hide();
      triggerErrorSwal('An error occurred loading data. Please try again later.');
    });
  });
  /** EVENT TRIGGER HANDLERS -- END */
}

function initBudgetDetails() {
  /**
   *
   * INITIAL LOAD EVENTS
   *
   */
  /** INITIAL LOAD EVENTS -- END */

  /**
   *
   * EVENT TRIGGER HANDLERS
   *
   */
  $('button#updateBank').on('click', (event) => {
    event.preventDefault();
    swal.fire({
      icon: 'warning',
      title: 'Are you sure you want to update the bank for this campaign using the spend from the Ad Account?',
      text: 'You won\'t be able to revert this',
      showCancelButton: true,
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger',
      },
    }).then((result) => {
      if (result.value) {
        $('.loader, #loading-overlay').show();

        $.ajax({ url: `/campaign/edit/budget/process?project_id=${projectID}&campaign=${campaignID}&action=update-bank`, type: 'get', dataType: 'json' })
          .done((data) => {
            const { success, message } = data;

            if (success) {
              swal.fire({
                icon: 'success',
                title: 'Bank updated successfully!',
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              }).then(() => window.window.location.reload());
            } else {
              triggerErrorSwal(message).then(() => { $('.loader, #loading-overlay').hide(); });
            }
          });
      }
    });
  });
  /** EVENT TRIGGER HANDLERS -- END */
}

function getBudgetingScalingAdditionalNotices() {
  let warningsToDisplay = '';

  if ($scalingBudgetAllowed.is(':checked') && window.prevBudgetingData.scalingBudgetAllowed === '0') {
    warningsToDisplay += '<p><strong>You enabled Scaling Budget - </strong> It is recommended to only use this feature when the Ad Account or Store (Amazon, Shopify, or WooCommerce) have been active at least 7 days. Your build(s) will now be budgeted based on the specified goals and account activity. The Campaign Bank will be ignored during optimization. Please ensure you select a build in the table below that you want to use with this feature.</p>';
  }

  return warningsToDisplay;
}

function initScalingBudget() {
  /**
   *
   * INITIAL LOAD EVENTS
   *
   */
  $('#budgetingForm-scaling').validate({
    invalidHandler() {
      $(this).addClass('was-validated');
    },
    submitHandler(form) {
      const formName = 'Budget Scaling';
      const $submitButton = $('#budgetingForm-scaling-submit');
      const action = 'update-budget-settings-scaling';
      const reloadWindow = true;
      const additionalNoticesTitle = 'Your Changes Will Impact Auto Optimization';

      // Check if our budget or build end dates are in the past
      let scalingBuildEndDateIsInPast = false;

      $('.utilizeForOverrun input').filter(':checked').each(function processBuildEndDates() {
        const id = $(this).attr('id');
        const idParts = id.split('_');
        const buildID = idParts[1];
        const startID = `buildStartDates_${buildID}`;
        const endID = `buildEndDates_${buildID}`;
        const end = $(`#${endID}`).val();
        const start = $(`#${startID}`).val();
        const endDateIsInThePast = (end.length === 0) ? false : moment(end).isBefore();
        const startDateIsInThePast = (start.length === 0) ? false : moment(start).isBefore();

        if (startDateIsInThePast && endDateIsInThePast) {
          scalingBuildEndDateIsInPast = true;
        }
      });

      const lifetimeBudgetEndDateIsInPast = (moment($lifetimeBudgetEnd.val()).isBefore());
      const lifetimeUninvoicedBudgetEndDate = $('#uninvoicedBudgetEnd').val();
      const lifetimeUninvoicedBudgetEndDateIsInPast = (lifetimeUninvoicedBudgetEndDate.length === 0) ? false : (moment(lifetimeUninvoicedBudgetEndDate).isBefore());

      // If Scaling is enabled in AO and the current Scaling Allowed is YES then if we are turning this off we need to ask about shutting it off in the ad account
      if (window.prevBudgetingData.scalingBudget === '1' && window.prevBudgetingData.scalingBudgetAllowed === '1' && !$scalingBudgetAllowed.is(':checked') && (scalingBuildEndDateIsInPast || lifetimeBudgetEndDateIsInPast || lifetimeUninvoicedBudgetEndDateIsInPast)) {
        swal.fire({
          title: 'Turn Off Build Now?',
          html: `<p>You are making a budget change which will result in the build shutting off. Do you want to shut off the build now?</p>
            <p>If you do not, then it will shut off automatically in the morning.</p>
            <p>Please be aware that shutting off the build may take some time. Do not close the window until the process completes, and you receive a confirmation message.</p>`,
          icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-danger',
            cancelButton: 'btn btn-secondary',
          },
          cancelButtonText: 'No, turn it off tomorrow',
          confirmButtonText: 'Yes, turn off the build',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            // Simply process the request
            processBudgetingForm(form, formName, $submitButton, action, reloadWindow, additionalNoticesTitle, getBudgetingScalingAdditionalNotices);
          } else {
            // Simply process the request
            processBudgetingForm(form, formName, $submitButton, action, reloadWindow, additionalNoticesTitle, getBudgetingScalingAdditionalNotices, '&nextDayShutOff=1');
          }
        });
      } else {
        // Simply process the request
        processBudgetingForm(form, formName, $submitButton, action, reloadWindow, additionalNoticesTitle, getBudgetingScalingAdditionalNotices);
      }
    },
  });
  /** INITIAL LOAD EVENTS -- END */

  /**
   *
   * EVENT TRIGGER HANDLERS
   *
   */
  $scalingBudgetAllowed.on('change', () => {
    if ($scalingBudgetAllowed.is(':checked') && !$scalingBudgetAllowed.is('[disabled]')) {
      $scalingBudgetAllowedItems.prop('readonly', false);

      if ($allowOverrun.is(':checked')) {
        $allowOverrun.click();
      }
    } else {
      $scalingBudgetAllowedItems.prop('readonly', true);
    }
  }).trigger('change');
  /** EVENT TRIGGER HANDLERS -- END */
}

function initRunAtMinimum() {
  /**
   *
   * INITIAL LOAD EVENTS
   *
   */
  endDateBudgetAtMinimum();
  setupBudgetingForm($('#budgetingForm-runAtMinimum'), 'Run at Minimum', $('#budgetingForm-runAtMinimum-submit'), 'update-budget-settings-run-at-minimum');
  /** INITIAL LOAD EVENTS -- END */

  /**
   *
   * EVENT TRIGGER HANDLERS
   *
   */
  setupBudgetingSectionHandler($('#budgetAtMinimum'), $('#budgetingForm-runAtMinimum .card input:not(.btn)'));
  /** EVENT TRIGGER HANDLERS -- END */
}

function getBudgetingOverrunAdditionalNotices() {
  let warningsToDisplay = '';

  if ($allowOverrun.is(':checked') && window.prevBudgetingData.allowOverrun === '0') {
    warningsToDisplay += '<p><strong>You enabled Allow Overrun - </strong> Allows build that have ended or campaigns with an expired Lifetime Budget End Date to continue running at a set budget. The Campaign Bank will be ignored during optimization. Please ensure you select a build in the table below that you want to use with this feature.</p>';
  }

  // Look for cases where we doubled the budget during this
  let doubledBudgetMessage = '';

  const currentDailyBudget = $dailyBudget.val();

  if ($allowOverrun.is(':checked')) {
    const newOverrunDailyBudget = $overrunDailyBudget.val();

    if (window.prevBudgetingData.allowOverrun === '1' && (parseFloatWithNaN(newOverrunDailyBudget) >= parseFloatWithNaN(window.prevBudgetingData.overrunDailyBudget) * 2)) {
      doubledBudgetMessage = `It appears you are increasing the Overrun Daily Budget from ${window.prevBudgetingData.overrunDailyBudget} to ${newOverrunDailyBudget} which is double, or more, than the current Overrun budget.`;
    } else if (window.prevBudgetingData.allowOverrun === '0' && (parseFloatWithNaN(newOverrunDailyBudget) >= parseFloatWithNaN(currentDailyBudget) * 2)) {
      doubledBudgetMessage = `It appears you are enabling Allow Overrun and setting the Overrun Daily Budget to ${newOverrunDailyBudget} which is double, or more, than the current Daily budget of ${currentDailyBudget} .`;
    }

    if (doubledBudgetMessage.length > 0) {
      warningsToDisplay += `<p><strong>You Doubled Your Daily Budget - </strong> ${doubledBudgetMessage} Your build(s) will now be budgeted based on the specified goals and account activity. The Campaign Bank will be ignored during optimization. If you did not mean to do this then please adjust and update your budgets.</p>`;
    }
  }

  return warningsToDisplay;
}

function initOverrun() {
  /**
   *
   * INITIAL LOAD EVENTS
   *
   */
  endDateOverrunBudget();
  $('#budgetingForm-overrun').validate({
    invalidHandler() {
      $(this).addClass('was-validated');
    },
    submitHandler(form) {
      const formName = 'Overrun';
      const $submitButton = $('#budgetingForm-overrun-submit');
      const action = 'update-budget-settings-overrun';
      const reloadWindow = true;
      const additionalNoticesTitle = 'Your Changes Will Impact Auto Optimization';

      // Check if our budget or build end dates are in the past
      let scalingBuildEndDateIsInPast = false;

      $('.utilizeForOverrun input').filter(':checked').each(function processBuildEndDates() {
        const id = $(this).attr('id');
        const idParts = id.split('_');
        const buildID = idParts[1];
        const startID = `buildStartDates_${buildID}`;
        const endID = `buildEndDates_${buildID}`;
        const end = $(`#${endID}`).val();
        const start = $(`#${startID}`).val();
        const endDateIsInThePast = (end.length === 0) ? false : moment(end).isBefore();
        const startDateIsInThePast = (start.length === 0) ? false : moment(start).isBefore();

        if (startDateIsInThePast && endDateIsInThePast) {
          scalingBuildEndDateIsInPast = true;
        }
      });

      const lifetimeBudgetEndDateIsInPast = (moment($lifetimeBudgetEnd.val()).isBefore());
      const lifetimeUninvoicedBudgetEndDate = $('#uninvoicedBudgetEnd').val();
      const lifetimeUninvoicedBudgetEndDateIsInPast = (lifetimeUninvoicedBudgetEndDate.length === 0) ? false : (moment(lifetimeUninvoicedBudgetEndDate).isBefore());

      // If we are turning off Allow Overrun we need to ask about shutting it off in the ad account
      if (window.prevBudgetingData.allowOverrun === '1' && !$allowOverrun.is(':checked') && (scalingBuildEndDateIsInPast || lifetimeBudgetEndDateIsInPast || lifetimeUninvoicedBudgetEndDateIsInPast)) {
        swal.fire({
          title: 'Turn Off Build Now?',
          html: `<p>You are making a budget change which will result in the build shutting off. Do you want to shut off the build now?</p>
            <p>If you do not, then it will shut off automatically in the morning.</p>
            <p>Please be aware that shutting off the build may take some time. Do not close the window until the process completes, and you receive a confirmation message.</p>`,
          icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-danger',
            cancelButton: 'btn btn-secondary',
          },
          cancelButtonText: 'No, turn it off tomorrow',
          confirmButtonText: 'Yes, turn off the build',
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            // Simply process the request
            processBudgetingForm(form, formName, $submitButton, action, reloadWindow, additionalNoticesTitle, getBudgetingOverrunAdditionalNotices);
          } else {
            // Simply process the request
            processBudgetingForm(form, formName, $submitButton, action, reloadWindow, additionalNoticesTitle, getBudgetingOverrunAdditionalNotices, '&nextDayShutOff=1');
          }
        });
      } else {
        // Simply process the request
        processBudgetingForm(form, formName, $submitButton, action, reloadWindow, additionalNoticesTitle, getBudgetingOverrunAdditionalNotices);
      }
    },
  });
  /** INITIAL LOAD EVENTS -- END */

  /**
   *
   * EVENT TRIGGER HANDLERS
   *
   */
  $allowOverrun.on('change', () => {
    if ($allowOverrun.is(':checked') && !$allowOverrun.is('[disabled]')) {
      $allowOverrunItems.prop('readonly', false);

      if ($scalingBudgetAllowed.is(':checked')) {
        $scalingBudgetAllowed.click();
      }
    } else {
      $allowOverrunItems.prop('readonly', true);
    }
  }).trigger('change');
  /** EVENT TRIGGER HANDLERS -- END */
}

function getBudgetingOverrideAdditionalNotices() {
  let warningsToDisplay = '';

  if ($overrideEnabled.is(':checked') && window.prevBudgetingData.overrideEnabled === '0') {
    warningsToDisplay += '<p><strong>You enabled Override - </strong> Your build(s) will now be budgeted at the Override Daily Budget instead of using the Daily Budget and Daily Portion of Campaign Bank. The Campaign Bank will be ignored during optimization. Builds will still be shut off after the Lifetime Budget End or Build End dates have passed unless used in conjunction with Allow Override or Scaling Budget.</p>';
  }

  // Look for cases where we doubled the budget during this
  let doubledBudgetMessage = '';

  const currentDailyBudget = $dailyBudget.val();

  if ($overrideEnabled.is(':checked')) {
    const newOverrideDailyBudget = $('#overrideDailyBudget').val();

    if (window.prevBudgetingData.overrideEnabled === '1' && (parseFloatWithNaN(newOverrideDailyBudget) >= parseFloatWithNaN(window.prevBudgetingData.overrideDailyBudget) * 2)) {
      doubledBudgetMessage = `It appears you are increasing the Override Daily Budget from ${window.prevBudgetingData.overrideDailyBudget} to ${newOverrideDailyBudget} which is double, or more, than the current Override budget.`;
    } else if (window.prevBudgetingData.overrideEnabled === '0' && (parseFloatWithNaN(newOverrideDailyBudget) >= parseFloatWithNaN(currentDailyBudget) * 2)) {
      doubledBudgetMessage = `It appears you are enabling Allow Override and setting the Override Daily Budget to ${newOverrideDailyBudget} which is double, or more, than the current Daily budget of ${currentDailyBudget} .`;
    }

    if (doubledBudgetMessage.length > 0) {
      warningsToDisplay += `<p><strong>You Doubled Your Daily Budget - </strong> ${doubledBudgetMessage} Your build(s) will now be budgeted based on the specified goals and account activity. The Campaign Bank will be ignored during optimization. If you did not mean to do this then please adjust and update your budgets.</p>`;
    }
  }

  return warningsToDisplay;
}

function initOverride() {
  /**
   *
   * INITIAL LOAD EVENTS
   *
   */
  initOverrideBudgetDatePickers();
  setupBudgetingForm($('#budgetingForm-override'), 'Override', $('#budgetingForm-override-submit'), 'update-budget-settings-override', false, 'Your Changes Will Impact Auto Optimization', getBudgetingOverrideAdditionalNotices);
  /** INITIAL LOAD EVENTS -- END */

  /**
   *
   * EVENT TRIGGER HANDLERS
   *
   */
  setupBudgetingSectionHandler($('#overrideEnabled'), $('#budgetingForm-override .card input:not(.btn)'));
  /** EVENT TRIGGER HANDLERS -- END */
}

function initEmergencyExtension() {
  /**
   *
   * INITIAL LOAD EVENTS
   *
   */
  $emergencyExtensionModalForm.validate({
    invalidHandler() {
      $(this).addClass('was-validated');
    },
    submitHandler(form) {
      $('.loader, #loading-overlay').show();
      $emergencyExtensionModalSubmitButton.prop('disabled', true);

      $.ajax({
        url: `/campaign/edit/budget/process?project_id=${projectID}&campaign=${campaignID}&action=emergency-budget-extension`,
        type: 'post',
        data: $(form).serialize(),
        dataType: 'json',
      })
        .done((data) => {
          $('.loader, #loading-overlay').hide();

          if (data.success) {
            swal.fire({
              icon: 'success',
              title: 'Campaign Emergency Extension Completed Successfully',
              timer: 3000,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            }).then(() => {
              $('.loader, #loading-overlay').show();
              $emergencyExtensionModalSubmitButton.prop('disabled', false);
              $emergencyExtensionModal.modal('hide');
              window.location.reload();
            });
          } else {
            triggerErrorSwal(data.message).then(() => {
              $emergencyExtensionModalSubmitButton.prop('disabled', false);
            });
          }
        })
        .fail((jqXHR, status, error) => {
          triggerErrorSwal(error).then(() => {
            $emergencyExtensionModalSubmitButton.prop('disabled', false);
          });
        });
    },
  });
  /** INITIAL LOAD EVENTS -- END */

  /**
   *
   * EVENT TRIGGER HANDLERS
   *
   */
  $('button#emergencyBudgetExtension').on('click', (event) => {
    event.preventDefault();
    $emergencyExtensionModal.appendTo('body').modal('show');
  });

  $emergencyExtensionModalSubmitButton.on('click', (event) => {
    event.preventDefault();
    $emergencyExtensionModalForm.submit();
  });
  /** EVENT TRIGGER HANDLERS -- END */
}

function initUpdateBudgetEnded() {
  $updateBudgetEnded.on('change', () => {
    let budgetEnded = '0';
    let title = 'Are you sure you want to unmark this campaign as ended?';
    let text = 'This will unmark the budget for this campaign as "cleared" and will re-enable editing of the budget.';
    let swalMessage = 'Active';

    if ($updateBudgetEnded.is(':checked')) {
      budgetEnded = '1';
      title = 'Are you sure you want to mark this campaign budget as ended?';
      text = 'This will mark the budget for this campaign as "cleared" and disables editing of the budget. Once enabled, spend and campaign bank will be no longer be updated.';
      swalMessage = 'Ended (Cleared)';
    }

    swal.fire({
      icon: 'warning',
      title,
      text,
      showCancelButton: true,
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger',
      },
    }).then((result) => {
      if (result.value) {
        $('.loader, #loading-overlay').show();

        $.ajax({ url: `/campaign/edit/budget/process?project_id=${projectID}&campaign=${campaignID}&action=update-budget-ended&budget_ended=${budgetEnded}`, type: 'get', dataType: 'json' })
          .done((data) => {
            const { success, message } = data;

            if (success) {
              swal.fire({
                icon: 'success',
                title: `Budget marked as ${swalMessage}!`,
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              }).then(() => window.window.location.reload());
            } else {
              triggerErrorSwal(message).then(() => {
                if (budgetEnded === '1') {
                  $updateBudgetEnded.prop('checked', false);
                } else {
                  $updateBudgetEnded.prop('checked', true);
                }
                $('.loader, #loading-overlay').hide();
              });
            }
          });
      } else {
        if (budgetEnded === '1') {
          $updateBudgetEnded.prop('checked', false);
        } else {
          $updateBudgetEnded.prop('checked', true);
        }
      }
    });
  });
}

export const init = () => {
  $(() => {
    // Converted
    $addBudgetModal = $('#addBudgetModal');
    $addBudgetModalForm = $('#addBudget-form');
    $addBudgetModalDailyBudget = $('#addBudget-budgetDaily');
    $addBudgetModalAddToLifeTimeBudget = $('#addBudget-budgetAddToLifetime');
    $addBudgetModalLifetimeBudgetEnd = $('#addBudget-lifetimeBudgetEnd');
    $addBudgetModalSubmitButton = $('#addBudgetModal #addBudget-submit');
    $addBudgetModalUseCalculatedBudgetDaily = $('#addBudget-useCalculatedBudgetDaily');
    $allowOverrun = $('#allowOverrun');
    $allowOverrunItems = $('#budgetingForm-overrun .card input:not(.btn)');
    $budgetAtMinimumEnd = $('#budgetAtMinimumEnd');
    $calculatedDailyBudget = $('#calculatedDailyBudget');
    $dailyBudget = $('#budgetDaily');
    $emergencyExtensionModal = $('#emergencyExtensionModal');
    $emergencyExtensionModalForm = $('#emergencyExtension-form');
    $emergencyExtensionModalSubmitButton = $('#emergencyExtension-submit');
    $lifetimeBudgetEnd = $('#lifetimeBudgetEnd');
    $lifetimeBudgetStart = $('#lifetimeBudgetStart');
    $overrideDailyBudgetEnd = $('#overrideDailyBudgetEnd');
    $overrideDailyBudgetStart = $('#overrideDailyBudgetStart');
    $overrideEnabled = $('.overrideEnabled input');
    $overrunDailyBudget = $('#overrunDailyBudget');
    $overrunDailyBudgetEnd = $('#overrunDailyBudgetEnd');
    $scalingBudgetAllowed = $('#budgetingForm-scaling input#budgetingScalingAllowed');
    $scalingBudgetAllowedItems = $('#budgetingForm-scaling .card input:not(.btn)');
    $updateBudgetModal = $('#updateBudgetModal');
    $updateBudgetModalForm = $('#updateBudget-form');
    $updateBudgetModalDailyBudget = $('#updateBudget-budgetDaily');
    $updateBudgetModalHasUninvoicedBudget = $('#updateBudget-hasUninvoicedBudget');
    $updateBudgetModalLifetimeBudgetEnd = $('#updateBudget-lifetimeBudgetEnd');
    $updateBudgetModalLifetimeBudgetStart = $('#updateBudget-lifetimeBudgetStart');
    $updateBudgetModalSubmitButton = $('#updateBudgetModal #updateBudget-submit');
    $updateBudgetModalUninvoicedBudget = $('#updateBudget-uninvoicedBudget');
    $updateBudgetModalUninvoicedBudgetFormGroup = $updateBudgetModalUninvoicedBudget.closest('.form-group');
    $updateBudgetModalUninvoicedBudgetBasedOnScaling = $('#updateBudget-uninvoicedBudgetBasedOnScaling');
    $updateBudgetModalUninvoicedBudgetEnd = $('#updateBudget-uninvoicedBudgetEnd');
    $updateBudgetModalUninvoicedBudgetItems = $('#updateBudgetModal .uninvoicedBudgetItems');
    $updateBudgetEnded = $('#budget_ended');

    initAddBudget();
    initUpdateBudget();
    initViewHistory();
    initBudgetDetails();
    initScalingBudget();
    initRunAtMinimum();
    initOverrun();
    initOverride();
    initEmergencyExtension();
    initUpdateBudgetEnded();
  });
};

export default { init };
