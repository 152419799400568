import swal from 'sweetalert2';
import Utilities from '../../Utilities';
import CampaignEditUtilities from './CampaignEditUtilities';

const businessID = Utilities.getUrlParameter('business');
const campaignID = Utilities.getUrlParameter('campaign_id');
const projectID = Utilities.getUrlParameter('project');

/**
 *
 * FUNCTIONS
 *
 */

export const init = () => {
  $(() => {
    /**
     *
     * INITIAL LOAD EVENTS
     *
     */

    /** INITIAL LOAD EVENTS -- END */

    /**
     *
     * EVENT TRIGGER HANDLERS
     *
     */

    $('#campaign_active').on('change', (event) => {
      $('#status-error-alert').hide();
      if ($(event.currentTarget).is(':checked')) {
        $.ajax({
          url: `/campaign/edit/process?project_id=${projectID}&campaign=${campaignID}`, type: 'get', data: $('#status-form').serialize(), dataType: 'json',
        })
          .done((data) => {
            if (data.success) {
              $('#campaign-disabled').slideUp(100);
              $('#status-submit').removeClass('btn-waiting').prop('disabled', false);
            } else {
              $('#status-error-body').html(data.message);
              $('#status-error-alert').slideDown(100);
              $('#status-submit').removeClass('btn-waiting').prop('disabled', false);
              $('#campaign_active').prop('checked', false);
            }
          })
          .fail((jqXHR, status, error) => {
            $('#status-error-body').html(error);
            $('#status-error-alert').slideDown(100);
            $('#status-submit').removeClass('btn-waiting').prop('disabled', false);
            $('#campaign_active').prop('checked', false);
          });
      } else {
        $('#campaign_active').prop('checked', true);
        $('#confirmDisable').appendTo('body').modal('show');
      }
    });

    $('#campaign_disabled').on('click', () => {
      $('#status-error-alert').hide();
      $('#campaign_active').prop('checked', false);
      $.ajax({
        url: `/campaign/edit/process?project_id=${projectID}&campaign=${campaignID}`, type: 'get', data: $('#status-form').serialize(), dataType: 'json',
      })
        .done((data) => {
          if (data.success) {
            $('#campaign-disabled').slideDown(100);
            $('#status-submit').removeClass('btn-waiting').prop('disabled', false);
          } else {
            $('#campaign_active').prop('checked', true);
            $('#status-error-body').html(data.message);
            $('#status-error-alert').slideDown(100);
            $('#status-submit').removeClass('btn-waiting').prop('disabled', false);
          }
          $('#confirmDisable').modal('hide');
        })
        .fail((jqXHR, status, error) => {
          $('#campaign_active').prop('checked', true);
          $('#status-error-body').html(error);
          $('#status-error-alert').slideDown(100);
          $('#status-submit').removeClass('btn-waiting').prop('disabled', false);
          $('#confirmDisable').modal('hide');
        });
    });

    /** EVENT TRIGGER HANDLERS -- END */
  });
};

export default { init };
