import swal from 'sweetalert2';
import Utilities from '../../Utilities';
import CampaignEditUtilities from './CampaignEditUtilities';

const businessID = Utilities.getUrlParameter('business');
const campaignID = Utilities.getUrlParameter('campaign_id');
const projectID = Utilities.getUrlParameter('project');

/**
 *
 * FUNCTIONS
 *
 */

export const init = () => {
  $(() => {
    /**
     *
     * INITIAL LOAD EVENTS
     *
     */

    /** INITIAL LOAD EVENTS -- END */

    /**
     *
     * EVENT TRIGGER HANDLERS
     *
     */

    $('.campaign_check').on('click', (e) => {
      const checkType = $(e.currentTarget).data('type');
      $(e.currentTarget).prop('disabled', true);
      $(`.${checkType}-spinner`).css('display', 'inline-block');

      if (checkType === 'pixel') {
        window.open(`https://business.facebook.com/events_manager2/list/pixel/${window.currentFacebookPixelID}/overview?business_id=${window.DEFAULT_FACEBOOK_BUSINESS_ID}&global_scope_id=${window.DEFAULT_FACEBOOK_BUSINESS_ID}&act=${window.currentFacebookAccountID}`);
      } else if (checkType === 'creative') {
        if (window.CREATIVE_SCORING_ENABLED) {
          window.open(`${window.SITE_URL}/creativescoring?business=${businessID}&project=${projectID}`);
        }
      }

      $.ajax({ url: `/campaign/edit/process?project_id=${projectID}&campaign=${campaignID}&campaign_check=${checkType}`, type: 'get' })
        .done((data) => {
          const { success, message, lastCheck: { checked_by_user: checkedByUser, created } } = JSON.parse(data);
          const check = checkType.charAt(0).toUpperCase() + checkType.slice(1);

          if (success) {
            $(`.${checkType}-check .last-checked span`).text(`${created} - just now`);
            $(`.${checkType}-check .checked-by-user span`).text(checkedByUser);

            swal.fire({
              icon: 'success',
              title: `Submitted ${check} Check Successfully`,
              timer: 3000,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            });

            $(`.${checkType}-spinner`).css('display', 'none');
            $(`.${checkType}-check`).show();
          } else {
            swal.fire({
              icon: 'error',
              title: message,
              timer: 3000,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            });

            $(e.currentTarget).prop('disabled', false);
            $(`.${checkType}-spinner`).css('display', 'none');
          }
        })
        .fail(() => {
          $(e.currentTarget).prop('disabled', false);
        });
    });

    /** EVENT TRIGGER HANDLERS -- END */
  });
};

export default { init };
